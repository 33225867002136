import 'isomorphic-fetch';
import { deviceDetect } from 'react-device-detect';
import { TOO_MANY_REQUESTS_ERROR } from '../app/constants/configuration.js';
import { UniqueIdGenerator } from '../app/helpers/uniqueIdGenerator';
import store from '../app/store';
import { refreshToken, removeToken, setAuthorized } from '../features/auth/authSlice';
import { Mutex } from 'async-mutex';

const { AUTH_API_BASE_URL } = window['RUN_CONFIG'];

const localStorageKey = 'access_token';
const mutex = new Mutex();
const deviceInfo = deviceDetect();

// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
export async function client(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey);
  const lang = window.localStorage.getItem('i18nextLng');
  const uniqueIdGenerator = await UniqueIdGenerator.get();
  const isUnsupportedOS = !["Windows", "Mac OS"].includes(deviceInfo.os) ||
                          !["Windows", "Mac OS"].includes(deviceInfo.osName);
  const isInvalidDevice = ["undefined", "none"].includes(deviceInfo.model) ||
                          ["undefined", "none"].includes(deviceInfo.vendor);
  let brand = '';
  let model = '';
  const newBody = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-client-platform': 'web',
    'x-client-lang': lang,
    'x-client-id': uniqueIdGenerator.getUniqueId()
  };

  if (isUnsupportedOS) {
    if (!isInvalidDevice) {
      brand = deviceInfo.vendor;
      model = deviceInfo.model;
    }

    if (brand && model) {
      newBody["x-client-app-brand"] = brand;
      newBody["x-client-app-model"] = model;
    }
  }

  const headers =
    body instanceof FormData
      ? { 'x-client-lang': lang }
      : newBody;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    credentials: 'include',
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };

  if (body) {
    config.body = body instanceof FormData ? body : JSON.stringify(body);
  }

  let data;
  try {
    let response = await fetch(AUTH_API_BASE_URL + endpoint, config);
    // many visits from one account
    if (response.status === 430) {
      throw new Error(TOO_MANY_REQUESTS_ERROR);
    }

    if (response.status === 204) {
      data = {
        success: true
      };
    } else {
      data = await response.json();
    }

    if (response.status === 422) {
      throw new Error(JSON.stringify(data.data));
    }
    if (response.status === 429) {
      throw new Error(JSON.stringify(data));
    }
    if (response.status === 403) {
      throw new Error(JSON.stringify(data));
    }
    if (response.status === 401 && localStorage.getItem('refresh_token')) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        const refreshResult = await store.dispatch(
          refreshToken({ refresh_token: localStorage.getItem('refresh_token') })
        );
        if (refreshResult.payload) {
          response = await fetch(AUTH_API_BASE_URL + endpoint, {
            ...config,
            headers: {
              ...headers,
              Authorization: `Bearer ${window.localStorage.getItem(localStorageKey)}`
            }
          });
          data = await response.json();
        } else {
          store.dispatch(setAuthorized(false));
          removeToken();
        }
        release();
      } else {
        response = await fetch(AUTH_API_BASE_URL + endpoint, {
          ...config,
          headers: {
            ...headers,
            Authorization: `Bearer ${window.localStorage.getItem(localStorageKey)}`
          }
        });
        data = await response.json();
        if (response.status === 401) {
          store.dispatch(setAuthorized(false));
          removeToken();
        }
      }
    }

    if (response.ok) {
      return data;
    }
    throw new Error(data.error);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'POST', body });
};

client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'PUT', body });
};

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'PATCH', body });
};

client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'DELETE', body });
};
